<template>
  <main>
    <Loading v-if="isLoading"></Loading>
    <div v-else>
      <h1 class="text-2xl font-bold">Visitation Log</h1>
      <div class="flex my-3">
        <button
          @click="handleBackBtn"
          class="block uppercase shadow ml-2 text-xs bg-blue-900 text-white hover:bg-blue-600 focus:shadow-outline focus:outline-none py-3 px-5 rounded"
        >
          &laquo; Back
        </button>
      </div>
      <div class="mt-10 pb-5" style="overflow: auto">
        <div
          class="w-11/12 border text-sm rounded-lg flex flex-col bg-white overflow-hidden"
        >
          <div class="bg-white px-6 py-4">
            <div class="text-sm text-gray-600 font-bold">
              Visit Information
            </div>
            <div
              class="text-sm text-gray-600 font-bold rounded text-right inherit w-auto"
            >
              <span
                class="text-sm text-gray-600 font-bold rounded text-right inherit w-auto"
                :class="
                  log.visitation.visitationStatus
                    ? 'bg-green-50 px-3 py-2 border-green-100 border-2'
                    : 'bg-red-50 px-3 py-2 border-red-100 border-2'
                "
                >{{
                  log.visitation.visitationStatus
                    ? "Visitation Completed"
                    : "Incomplete"
                }}</span
              >
            </div>
          </div>
          <div class="px-3 py-4 flex flex-row border-t border-gray-200">
            <div class="grid-cols-2 text-sm rounded-lg p-4 bg-white">
              <div class="text-sm text-gray-600 font-bold">
                {{ log.businessName || "N/A" }}
              </div>
              Business Name
            </div>
            <div class="grid-cols-2 text-sm rounded-lg p-4 bg-white">
              <div class="text-sm text-gray-600 font-bold">
                {{ log.businessName || "N/A" }}
              </div>
              Merchant email
            </div>
            <div class="grid-cols-2 text-sm rounded-lg p-4 bg-white">
              <div class="text-sm text-gray-600 font-bold">
                {{ log.classification || "N/A" }}
              </div>
              Classification
            </div>
            <div class="grid-cols-2 text-sm rounded-lg p-4 bg-white">
              <div class="text-sm text-gray-600 font-bold">
                {{ log.visitation.store.name || "N/A" }}
              </div>
              Store name
            </div>
            <div class="grid-cols-2 text-sm rounded-lg p-4 bg-white">
              <div class="text-sm text-gray-600 font-bold">
                {{ log.visitation.storeId || "N/A" }}
              </div>
              Store ID
            </div>
            <div class="grid-cols-2 text-sm rounded-lg p-4 bg-white">
              <div class="text-sm text-gray-600 font-bold">
                {{ log.businessId || "N/A" }}
              </div>
              Business ID
            </div>
          </div>
          <div class="px-3 py-4 flex flex-row border-t border-gray-200">
            <div class="grid-cols-2 text-sm rounded-lg p-4 bg-white">
              <div class="text-sm text-gray-600 font-bold">
                {{ log.storeLocation || "N/A" }}
              </div>
              Location Address
            </div>
            <div class="grid-cols-2 text-sm rounded-lg p-4 bg-white">
              <div class="text-sm text-gray-600 font-bold">
                {{ log.cityLGA || "N/A" }}
              </div>
              City/LGA
            </div>
            <div class="grid-cols-2 text-sm rounded-lg p-4 bg-white">
              <div class="text-sm text-gray-600 font-bold">
                {{ log.state || "N/A" }}
              </div>
              State
            </div>
            <div class="grid-cols-2 text-sm rounded-lg p-4 bg-white">
              <div class="text-sm text-gray-600 font-bold">
                {{ log.numberOfTerminals || "N/A" }}
              </div>
              Terminals at location
            </div>
            <!-- <div class="grid-cols-2 text-sm rounded-lg p-4 bg-white">
              <div class="text-sm text-gray-600 font-bold">
                {{ log.visitation.visitationReason || "N/A" }}
              </div>
              Visitation Reason
            </div> -->
          </div>
        </div>
      </div>
      <div class=" pb-5" style="overflow: auto">
        <div
          class="w-11/12 border text-sm rounded-lg flex flex-col bg-white overflow-hidden"
        >
          <div class="bg-white px-6 py-4">
            <div class="text-sm text-gray-600 font-bold">
              Visitation activity
            </div>
          </div>
          <div class="px-3 py-4 flex flex-row border-t border-gray-200">
            <div class="grid-cols-2 text-sm rounded-lg p-4 bg-white">
              <div class="text-sm text-gray-600 font-bold">
                {{ log.visitation.msoName || "N/A" }}
              </div>
              MSO name
            </div>
            <div class="grid-cols-2 text-sm rounded-lg p-4 bg-white">
              <div class="text-sm text-gray-600 font-bold">
                {{ log.visitation.msoEmail || "N/A" }}
              </div>
              MSO email
            </div>
            <div class="grid-cols-2 text-sm rounded-lg p-4 bg-white">
              <div class="text-sm text-gray-600 font-bold">
                {{
                  moment(log.visitation.visitationDate).format(
                    "DD MMM YYYY - h:mm:ss a"
                  ) || "N/A"
                }}
              </div>
              Visitation date
            </div>
          </div>
          <div class="px-3 pt-4 flex flex-row">
            <h5 class="px-4 font-bold">Visitation Checklist</h5>
          </div>
          <div class="px-3 py-4 flex flex-row">
            <div class="grid-cols-2 text-sm rounded-lg p-4 bg-white">
              <div class="text-sm text-gray-600 font-bold">
                <i
                  class="bi bi-check-square-fill"
                  v-if="
                    log.visitation.visitationLog.visitationChecklist
                      .connectivityTest
                  "
                ></i>
                <i class="bi bi-x-square-fill" v-else></i>
                <label for="visitationChecklist"> Do Connectivity test</label>
              </div>
            </div>
            <div class="grid-cols-2 text-sm rounded-lg p-4 bg-white">
              <div class="text-sm text-gray-600 font-bold">
                <i
                  class="bi bi-check-square-fill"
                  v-if="
                    log.visitation.visitationLog.visitationChecklist
                      .paperRollCheck
                  "
                ></i>
                <i class="bi bi-x-square-fill" v-else></i>
                <label for="paperRolls"> Check for paper rolls</label>
              </div>
            </div>
            <div class="grid-cols-2 text-sm rounded-lg p-4 bg-white">
              <div class="text-sm text-gray-600 font-bold">
                <i
                  class="bi bi-check-square-fill"
                  v-if="
                    log.visitation.visitationLog.visitationChecklist
                      .terminalRetrieval
                  "
                ></i>
                <i class="bi bi-x-square-fill" v-else></i>
                <label for="visitationChecklist"> Retrieve terminal</label>
              </div>
            </div>
            <div class="grid-cols-2 text-sm rounded-lg p-4 bg-white">
              <div class="text-sm text-gray-600 font-bold">
                <i
                  class="bi bi-check-square-fill"
                  v-if="
                    log.visitation.visitationLog.visitationChecklist
                      .chargerCheck
                  "
                ></i>
                <i class="bi bi-x-square-fill" v-else></i>
                <label for="visitationChecklist"> Check for charger</label>
              </div>
            </div>
            <div class="grid-cols-2 text-sm rounded-lg p-4 bg-white">
              <div class="text-sm text-gray-600 font-bold">
                <i
                  class="bi bi-check-square-fill"
                  v-if="
                    log.visitation.visitationLog.visitationChecklist
                      .batteryCheck
                  "
                ></i>
                <i class="bi bi-x-square-fill" v-else></i>
                <label for="visitationChecklist"> Check for battery</label>
              </div>
            </div>
            <div class="grid-cols-2 text-sm rounded-lg p-4 bg-white">
              <div class="text-sm text-gray-600 font-bold">
                <i
                  class="bi bi-check-square-fill"
                  v-if="
                    log.visitation.visitationLog.visitationChecklist
                      .surveyCompletion
                  "
                ></i>
                <i class="bi bi-x-square-fill" v-else></i>
                <label for="visitationChecklist"> Complete survey</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class=" pb-5" style="overflow: auto">
        <div
          class="w-11/12 border text-sm rounded-lg flex flex-col bg-white overflow-hidden"
        >
          <div class="bg-white px-6 py-4">
            <div class="text-sm text-gray-600 font-bold">
              Inactivity inquiry
            </div>
          </div>
          <div class="px-3 py-1 flex flex-row">
            <ul>
              <li
                :key="i"
                v-for="(inactive, i) in log.visitation.visitationLog
                  .inactivityCheck.reasons"
              >
                <div class="text-sm text-gray-600 font-bold">
                  <i
                    class="bi bi-check-square-fill"
                    v-if="
                      log.visitation.visitationLog.visitationChecklist
                        .surveyCompletion
                    "
                  ></i>
                  <label for="visitationChecklist"> {{ inactive }}</label>
                </div>
              </li>
            </ul>
          </div>
          <div class="px-3 py-1 flex flex-row border-t border-gray-200">
            <div class="grid-cols-2 text-sm rounded-lg p-4 bg-white">
              Additional comments
              <div class="text-sm text-gray-600 font-bold">
                {{
                  log.visitation.visitationLog.inactivityCheck.comment || "N/A"
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class=" pb-5" style="overflow: auto">
        <div
          class="w-11/12 border text-sm rounded-lg flex flex-col bg-white overflow-hidden"
        >
          <div class="bg-white px-6 py-4">
            <div class="text-sm text-gray-600 font-bold">
              MSO Remarks
            </div>
          </div>
          <div class="px-3 py-1 flex flex-row border-t border-gray-200">
            <div class="grid-cols-2 text-sm rounded-lg p-4 bg-white">
              <div class="text-sm text-gray-600">
                <ul>
                  <li
                    v-for="(log, i) in log.visitation.visitationLog.msoRemarks"
                    :key="i"
                  >
                    {{ log }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class=" pb-5" style="overflow: auto">
        <div
          class="w-11/12 border text-sm rounded-lg flex flex-col bg-white overflow-hidden"
        >
          <div class="bg-white px-6 py-4">
            <div class="text-sm text-gray-600 font-bold">
              MSO Comments
            </div>
          </div>
          <div class="px-3 py-1 flex flex-row border-t border-gray-200">
            <div class="grid-cols-2 text-sm rounded-lg p-4 bg-white">
              <div class="text-sm text-gray-600">
                <ul>
                  <li>
                    {{ log.visitation.visitationLog.msoComment }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex gap-4 pb-5" style="overflow: auto">
        <div
          class="w-1/2 border text-sm rounded-lg flex flex-col bg-white overflow-hidden"
        >
          <div class="bg-white px-6 py-4">
            <div class="text-sm text-gray-600 font-bold">
              Dangler
            </div>
          </div>
          <div class="px-3 py-1 flex flex-row">
            <div class="grid-cols-2 text-sm rounded-lg p-4 bg-white">
              <div class="text-sm text-gray-600 font-bold">
                <i
                  class="bi bi-check-square-fill"
                  v-if="
                    log.visitation.visitationLog.danglerCheck.conditionCheck
                  "
                ></i>
                <i class="bi bi-x-square-fill" v-else></i>
                <label for="danglerCheck">
                  Dangler/Stand in good condition</label
                >
              </div>
            </div>
          </div>
          <div class="px-3 py-1 flex flex-row border-t border-gray-200">
            <div class="grid-cols-2 text-sm rounded-lg p-4 bg-white">
              <img
                :src="log.visitation.visitationLog.danglerCheck.imageUrl"
                class="img-thumbnail"
              />
            </div>
          </div>
        </div>
        <div
          class="w-1/2 border text-sm rounded-lg flex flex-col bg-white overflow-hidden"
        >
          <div class="bg-white px-6 py-4">
            <div class="text-sm text-gray-600 font-bold">
              Test Transaction
            </div>
          </div>
          <div class="px-3 py-1 flex flex-row">
            <div class="grid-cols-2 text-sm rounded-lg p-4 bg-white">
              <div class="text-sm text-gray-600 font-bold">
                <i
                  class="bi bi-check-square-fill"
                  v-if="
                    log.visitation.visitationLog.transactionReceiptCheck
                      .transactionMade
                  "
                ></i>
                <i class="bi bi-x-square-fill" v-else></i>
                Have you carried out a transaction?
              </div>
            </div>
          </div>
          <div class="px-3 py-1 flex flex-row border-t border-gray-200">
            <div class="grid-cols-2 text-sm rounded-lg p-4 bg-white">
              <img
                :src="
                  log.visitation.visitationLog.transactionReceiptCheck.imageUrl
                "
                class="img-thumbnail"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import Loading from "./general-components/Loading.vue";
import moment from "moment";
export default {
  data() {
    return {
      moment,
      isLoading: false,
      search: "",
      accounts: null,
      log: null,
    };
  },
  components: {
    Loading,
  },
  filters: {
    truncate: function(text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
  created() {
    this.log = this.$route.params.item;
  },
  methods: {
    handleBackBtn() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.bi {
  color: #1e3a8a;
}
.img-thumbnail {
  width: 500px;
  height: 500px;
}
table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}
table tr th:last-child {
  border-right: 1px solid rgb(241, 241, 241);
}
table tr th,
table tr td {
  /* border-right: 1px solid #bbb; */
  border-bottom: 1px solid rgb(238, 238, 238);
  padding: 18px;
}
table tr th:first-child,
table tr td:first-child {
  /* border-right: 1px solid rgb(223, 223, 223); */
  border-left: 1px solid rgb(241, 241, 241);
}
table tr th {
  background: rgb(255, 255, 255);
  border-top: 1px solid rgb(241, 241, 241);
  text-align: left;
}
table tr td {
  background: #f0f6fd;
}
/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
</style>
